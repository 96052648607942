<template>
  <div class="card full-width">
    <div class="card-header border-0 pt-6 full-width">
      <!--begin::Card title-->

      <div class="row full-width">
        <div class="col-md-4 fv-row">
          <el-input
              v-model="filters.search"
              class="m-2"
              size="large"
              placeholder="Please Input"
              :suffix-icon="Search"
          ></el-input>
        </div>

      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
          class="table-button-actions-width"
          :total="total"
          :rowsPerPage="perPage"
          :loading="loading"
          :table-data="tableData"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          @current-change="onCurrentChange"
          @sort="sort"
          @items-per-page-change="changePerPage"
      >
        <template v-slot:cell-id="{ row: data }">{{ data.id }}</template>
        <template v-slot:cell-first_name="{ row: data }">{{ data.first_name }}</template>
        <template v-slot:cell-last_name="{ row: data }">{{ data.last_name }}</template>
        <template v-slot:cell-phone="{ row: data }">{{ data.phone }}</template>
        <template v-slot:cell-wallet="{ row: data }">
                    <span class="badge fs-7 fw-bolder" :class="`badge-light-${data.wallet.class}`">{{
                        data.wallet.value
                      }}</span>
        </template>
        <template v-slot:cell-actions="{ row: data }">
          <el-row style="width: 200px;">
            <el-button type="default" v-can="'providers.show'" size="small" @click="viewResource(data.id)" :icon="View" circle></el-button>
            <el-button type="primary" v-can="'providers.update'" size="small" @click="editResource(data.id)" :icon="Edit" circle></el-button>
            <el-button :loading="data.loading" :disabled="data.loading" type="primary" v-can="'providers.exceed-wallet-limit'" size="small" @click="notify(data)" plain>Notify</el-button>
          </el-row>
        </template>
      </Datatable>
    </div>

  </div>
</template>

<script>
import {defineComponent, ref, onMounted, watch, reactive} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import {Search, Edit, Delete, View} from '@element-plus/icons-vue'
import {handleError, handleSuccess} from "@/core/helpers/response";
import {APIs} from "@/store/enums/ApiEnums";
import CheckboxTableHeader from "@/components/admin/general/CheckboxTableHeader";
import {useRouter, useRoute} from 'vue-router'
import Swal from "sweetalert2/dist/sweetalert2.js";
import {confirmGeneralMessage} from "@/core/helpers/custom_notification";

export default defineComponent({
  name: "provider-exceed-wallet-limit-list",
  components: {
    Datatable, CheckboxTableHeader
  },

  setup() {
    const router = useRouter();
    const tableHeader = ref([
      {name: "ID", key: "id", sortable: true,},
      {name: "First Name", key: "first_name", sortable: true,},
      {name: "Last Name", key: "last_name", sortable: true,},
      {name: "Phone", key: "phone", sortable: false,},
      {name: "Wallet", key: "wallet", sortable: false,},
      {name: "Actions", key: "actions",},
    ]);

    const tableData = ref([]);
    const loading = ref(true);
    const total = ref(0);
    const currentPage = ref(1);
    const perPage = ref(0);
    const filters = ref({
      search: '',
      status: '',
      page: 1,
      per_page: 10
    });

    const filterOptions = ref({statuses: []});

    onMounted(() => {
      setCurrentPageBreadcrumbs("Provider Exceed Wallet limit", [], null);
    });


    const fetchData = async () => {
      loading.value = true;
      try {
        filters.page = currentPage.value;
        let response = await ApiAxiosService.get(APIs.PROVIDER_EXCEEDED_WALLET_LIMIT.list, filters.value);
        let data = response.data.data;
        tableData.value = data.providers;
        total.value = data.pagination_options.last_page;
        currentPage.value = data.pagination_options.currentPage;
        perPage.value = 1;
        loading.value = false;
      } catch (error) {
        handleError(error)
      }

    };

    const onCurrentChange = (value) => {
      tableData.value = [];
      filters.value.page = value;
    };
    const changePerPage = (value) => {
      filters.value.per_page = value;
    }

    const sort = (data) => {
    }
    const notify = async (data) => {
      var checkConfirm = await confirmGeneralMessage(`Notify Provider (${data.title})`, `Are you sure notify provider for exceeded his wallet limit ?`, "Yes");
      if (checkConfirm.isConfirmed) {
        Swal.fire({text: 'Sending ...'})
        data.loading = true;
        ApiAxiosService.get(APIs.PROVIDER_EXCEEDED_WALLET_LIMIT.notify(data.id)).then(function (response) {
          handleSuccess(response, 'swal');
          fetchData();
          data.loading = false;
        }).catch(function (error) {
          data.loading = false;
          handleError(error, 'swal');
        });

      }
    };
    watch(filters.value, (currentValue, oldValue) => {
      fetchData();
    });


    const editResource = (id) => {
      router.push({name: 'edit-provider', params: {id: id}});
    }
    const viewResource = (id) => {
      router.push({name: 'view-provider', params: {id: id}});
    }


    fetchData();

    return {
      tableData,
      tableHeader,
      loading,
      fetchData,
      total,
      perPage,
      onCurrentChange,
      changePerPage,
      filters,
      sort,
      filterOptions,

      editResource,
      viewResource,
      notify,
      Search, Edit, Delete, View

    };
  },

});
</script>
